import * as React from 'react';
import { typeVersionObj } from './Version';

//セッション管理URLの型
type typeSessionUri = {
    login:string,
    check:string,
    logout:string,
    twofactor:string
}

//セッションデータの型
export type typeSession = {
    loaded:boolean,
    hasSession:boolean,
    isAdmin:boolean,
    language:string,
    timezone:string,
    datetimeFormat:string,
    device:string,
    timedOut:boolean,
    isLefty:boolean,
    skipStraight:boolean,
    disableDoubleClick:boolean,
    unit:string,
    noAds:boolean,
    routeColor:{
        default:string,
        hover:string
    },
    defaultLocation:{
        lat:number,
        lng:number
    },
    map:string
};

//汎用アラートデータの型
export type typeCommonAlert = {
    open:boolean;
    title:string;
    message:string;
    button:string;
    callback?:(() => void) | null;
}

export type typeConfirmation = {
    open:boolean,
    agree:boolean,
    force:boolean,
    datetime:Date|null
}

//セッションコンテキストの型
type typeContextSession = {
    sessionInfo:typeSession,
    setSessionInfo:React.Dispatch<React.SetStateAction<typeSession>>,
    backdrop:boolean,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>,
    commonAlert:typeCommonAlert,
    setCommonAlert:React.Dispatch<React.SetStateAction<typeCommonAlert>>,
    version:typeVersionObj,
    setVersion:React.Dispatch<React.SetStateAction<typeVersionObj>>,
    adsHeight:number,
    setAdsHeight:React.Dispatch<React.SetStateAction<number>>,
    confirmation:typeConfirmation,
    setConfirmation:React.Dispatch<React.SetStateAction<typeConfirmation>>
}

//通信処理の型 ( login )
type typeLogin = {
    account?:string,
    password?:string,
    language?:string,
    onThen?:(content?:any) => void,
    onCatch?:(err?:any) => void,
    onFinally?:() => void,
};

//通信処理の型 ( check )
type typeCheck = {
    update?:boolean,
    profile?:boolean,
    onThen?:(content?:any) => void,
    onCatch?:(err?:any) => void,
    onFinally?:() => void,
};

//通信処理の型 (  logout )
type typeLogout = {
    onThen?:(content?:any) => void,
    onCatch?:(err?:any) => void,
    onFinally?:() => void,
};

//通信処理の型 ( twoFactor )
type typeTwoFactor = {
    uuid?:string,
    code?:string,
    onThen?:(content?:any) => void,
    onCatch?:(err?:any) => void,
    onFinally?:() => void,
};

//テスト設定
export const isTesting:boolean = false; //テスト時は True
let isFirstReq:boolean = true;
//※ テスト時は通信しない

//Cookie設定
export const cookieEnabled:boolean = false; //true の場合はCookie、false の場合はローカルストレージ
const cookieName:string = "cycrouteid"; //Cookie名称 ( ローカルストレージ版でも共用 )

//URL設定
export const baseUri:string = process.env.REACT_APP_LOGIN_SERVER??'https://api.cycroute.com/';
export const sessionUri:typeSessionUri = {
    login:baseUri + 'login', //ログインURI
    check:baseUri + 'session', //セッションチェックURI
    logout:baseUri + 'logout', //ログアウトURI
    twofactor:baseUri + 'twofactor' //二段階認証URI
};

//セッション共有
export const SessionContext = React.createContext<typeContextSession>(undefined as any);
export const useSessionContext = () => React.useContext(SessionContext);

//セッション管理クラス
export class sessionIO {

    public name:{inLocal:string,onHttp:string};
    public cookieEnabled:boolean;

    constructor(){
        this.name = {
            inLocal:cookieName, //localStorage内のセッションIDの名称
            onHttp:cookieName //HTTPヘッダ内のセッションIDの名称 
        };
        this.cookieEnabled = cookieEnabled;
        //※ Cookieを使用する場合はカスタムヘッダおよびローカルストレージは使用しない
    }

    //ローカルストレージIO
    load():string{
        let res:string = "";
        if(cookieEnabled){ //Cookie版
            let onCookie = this.getCookie();
            res = onCookie?onCookie:'';
        }else{ //ローカルストレージ版
            let onLocal = window.localStorage.getItem(this.name.inLocal);
            res = onLocal?onLocal:'';
        }
        return res;
    }
    save(newValue?:string):void{
        if(typeof newValue !=='undefined'){
            if(cookieEnabled){ //Cookie版
                this.setCookie(newValue);
            }else{ //ローカルストレージ版
                window.localStorage.setItem(this.name.inLocal,newValue);
            }
        }
    }
    remove():void{
        if(cookieEnabled){ //Cookie版
            this.removeCookie();
        }else{ //ローカルストレージ版
            window.localStorage.removeItem(this.name.inLocal);
        }
    }

    //fetchコマンド用ヘッダ取得
    getHeader():any{
        if(cookieEnabled){ //Cookie版はカスタムヘッダ不要
            return {};
        }else{ //ローカルストレージ版
            let currentId = this.load();
            return JSON.parse('{"' + this.name.onHttp + '":"' + currentId + '"}');
        }
    }

    //cookie値を連想配列として取得する
    getCookieArray():any{
        let arr:any = [];
        if(document.cookie !== ''){
            let tmp:string[] = document.cookie.split(';');
            for(let i=0;i<tmp.length;i++){
                let data = tmp[i].split('=');
                arr[data[0].trim()] = decodeURIComponent(data[1].trim());
            }
        }
        return arr;
    }

    //cookie値取得
    getCookie():string{
        let arr = this.getCookieArray();
        return arr[cookieName]?arr[cookieName]:'';
    }

    //cookie値削除
    removeCookie(){
        document.cookie = cookieName + "=; max-age=0";
    }

    //cookie値設定
    setCookie(newValue:string){
        document.cookie = cookieName + "=" + newValue + ";";
    }

    //ログイン
    login({account='',password='',language='en',onThen=()=>{},onCatch=()=>{},onFinally=()=>{}}:typeLogin){
        if(isTesting){
            onThen({"success":true,"admin":true});
            onFinally();
        }else{
            let req = sessionUri.login + '?account=' + encodeURIComponent(account) + '&password=' + encodeURIComponent(password) + '&language=' + encodeURIComponent(language);
            fetch(
                req,cookieEnabled?{}:{mode:'cors'}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                // console.log('content : ',content);
                if(!content.twofactor){
                    if(typeof content.sessionid === 'string'){
                        this.save(content.sessionid);
                    }    
                }
                onThen(content)
            }).catch(err => {
                console.log('err : ',err);
                onCatch(err)
            }).finally(() => {
                onFinally()
            });
        }
    }

    //セッションチェック
    check({update=false,profile=false,onThen=()=>{},onCatch=()=>{},onFinally=()=>{}}:typeCheck){

        if(isTesting){
            if(isFirstReq){
                onThen({hasSession:false,admin:false,language:'en',loaded:true});
                isFirstReq = false;
            }else{
                onThen({hasSession:true,admin:true,language:'en',loaded:true});
            }
            onFinally();
        }else{
            let req = sessionUri.check + "?update=" +(update?"true":"false") + "&profile=" +(profile?"true":"false");
            fetch(
                req,cookieEnabled?{}:{mode:'cors',headers:this.getHeader()}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                console.log(`${req}`,content)
                onThen(content)
            }).catch(err => {
                onCatch(err)
            }).finally(() => {
                onFinally()
            });
        }
    }

    //ログアウト
    logout({onThen=()=>{},onCatch=()=>{},onFinally=()=>{}}:typeLogout){
        if(isTesting){
            this.remove();
            onThen({hasSession:false});
            onFinally();
        }else{
            fetch(
                sessionUri.logout,cookieEnabled?{}:{mode:'cors',headers:this.getHeader()}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                onThen(content);
                this.remove();
            }).catch(err => {
                onCatch(err)
            }).finally(() => {
                onFinally()
            });
        }
    }

    //二段階認証
    twoFactor({uuid='',code='',onThen=()=>{},onCatch=()=>{},onFinally=()=>{}}:typeTwoFactor){
        if(isTesting){
            onThen({"success":true,"admin":true});
            onFinally();
        }else{
            let req = sessionUri.twofactor + '?uuid=' + encodeURIComponent(uuid) + '&code=' + encodeURIComponent(code);
            fetch(
                req,cookieEnabled?{}:{mode:'cors'}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                if(typeof content.sessionid === 'string'){
                    this.save(content.sessionid);
                }
                onThen(content)
            }).catch(err => {
                onCatch(err)
            }).finally(() => {
                onFinally()
            });
        }
    }

}

//※ セッションが有効な場合のみ実行すること
export function syncSession(online:any,setSessionInfo:React.Dispatch<React.SetStateAction<typeSession>>){
    // console.log("online",online);
    if(online){
        setSessionInfo((prev)=>({...prev,...{
            loaded:true,
            hasSession:true,isAdmin:online.is_admin?online.is_admin:false,
            language:online.language,
            timezone:online.timezone,
            datetimeFormat:online.datetime_format,
            isLefty:online.is_lefty,
            skipStraight:online.skip_straight,
            disableDoubleClick:online.disable_double_click,
            unit:online.unit,
            map:online.map,
            noAds:online.no_ads,
            defaultLocation:{
                lat:online.default_location_y,
                lng:online.default_location_x
            }}}));
    }
}

const UIDname:string = "uid";

export function compareUID(UID:string){
    const oldUID = window.localStorage.getItem(UIDname);
    const isSame:boolean = (oldUID === UID);
    window.localStorage.setItem(UIDname,UID);
    return isSame;
}

const secreenTree = 'screen';

//画面遷移情報汎用
function screenCommon(){
    let data = window.localStorage.getItem(secreenTree);
    data = ((data===null)?'{}':data);
    let obj:any = {};
    try{
        obj = JSON.parse(data)
    }catch(e:any){
        obj = {};
    }
    return obj;
}

//画面遷移情報SAVE
export function screenSave(from:string,to:string){
    let obj:any = screenCommon();
    obj[to] = {"from":from}
    const data:string = JSON.stringify(obj);
    window.localStorage.setItem(secreenTree,data);
}

//画面遷移情報LOAD
export function screenLoad(to:string,FLG_DELETE:boolean=false){
    let from = '';
    let obj:any = screenCommon();
    try{
        if(obj[to]!==null && obj[to]!==undefined){
            if(obj[to].from!==null && obj[to].from!==undefined){
                from = `${obj[to].from}`;
                if(FLG_DELETE){
                    delete obj[to];
                    const data:string = JSON.stringify(obj);
                    window.localStorage.setItem(secreenTree,data);
                }
            }
        }
    }catch(error){
        console.log('Error :',error);
    }
    return from;
}

//画面遷移情報DELETE
export function screenDelete(to:string){
    let obj:any = screenCommon();
    try{
        if(obj[to]!==null){
            delete obj[to];
            const data:string = JSON.stringify(obj);
            window.localStorage.setItem(secreenTree,data);
        }
    }catch(error){
        console.log('Error :',error);
    }
}




